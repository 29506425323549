//
// .title-area is used for the ECOM-hosted top nav on small screens
// It contains the logo, hamburger icon, search icon, cart, and sometimes
// a find-in-store or similar additional link as a button.
//
// Because of mobile-first, these get inherited up and affect medium/large screen layouts
//
// The classes are also reused in the "secure" header for Checkout, even though
// Foundation TopBar isn't actually leveraged in Checkout.
//
// TODO: Most of this should be removed and rely more on Foudation TopBar directly,
// but it's probaby easier just to migrate to the CMS Top Nav instead

@import 'global/z-indexes';

$topbar-store-logo-z-index: z-index(topnav) !default;

//
// Buttons in the small screen header
//
@mixin topbar-smallheader {
	border-left: $topbar-menu-border;
	display: inline-block;
	float: left;
	height: $mobile-topbar-height;
	margin: 0;
	position: relative;
	text-align: center;
	width: $mobile-topbar-button-width;

	a {
		height: $mobile-topbar-height;
		left: 0;
		padding: 0;
		position: absolute;
		top: 0;
		width: $mobile-topbar-button-width;
	}

	i {
		display: inline-block;
		position: absolute;
	}

	.cart-icon {
		position: static;
		width: auto;

		span {
			color: $fontLinkHover;
		}
	} // .cart

	&:hover {
		background-color: $topbar-menu-bg-color-hover;
		cursor: pointer;
	}

	.active {
		background-color: $topbar-menu-bg-color-hover;
	}
}

.master-header {
	.top-bar,
	.top-bar-acord {
		height: $mobile-topbar-height;

		.title-area {
			float: right;
			height: $mobile-topbar-height;
			overflow: hidden;
			padding: 0;
			z-index: 1;

			> li {
				display: inline-block;
			}

			.menu-topbar {
				@include topbar-smallheader;
			}
		}

		// Logo
		.name {
			float: left;
			height: $mobile-topbar-height;
			position: relative;

			@include breakpoint(medium) {
				height: $tablet-topbar-height;
				padding: 0;
				z-index: $topbar-store-logo-z-index;
			}

			@include breakpoint(large) {
				height: $topbar-height;
			}
		}

		@include breakpoint(medium) {
			height: $tablet-topbar-height;

			.title-area {
				height: $tablet-topbar-height;

				.menu-topbar {
					display: none; // These would normally be hidden automatically by Foundation, but the display: inline-block on small to override list styles requires this
				}
			}
		}

		@include breakpoint(large) {
			height: $topbar-height;

			.title-area {
				height: $topbar-height;
			}
		}
	}
}
