@import 'global/topbar/topbar-fixed';

.fixed-small {
	.master-header {
		.top-bar,
		.top-bar-acord {
			.upper,
			.lower {
				background: get-color(nero-2);

				@include breakpoint(medium) {
					background: $white;
				}
			}
		}
	}
}
