@import 'global/topbar/topbar-search';

@mixin topbar_search_vans() {
	input {
		// text button
		&.search-button {
			font-family: get-font('franklin-gothic');
			font-weight: normal;
			text-transform: uppercase;
		}
	}
}

@if $include-html-topbar-search-classes != false {
    .master-header .search-form-container {
        @include topbar_search_vans();
    }
}
