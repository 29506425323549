
$topbar-search-container-icon-font-size: em-calc(20) !default;
$topbar-search-dropdown-arrow-size: em-calc(16px) !default;
$topbar-search-dropdown-background: rgba(0, 0, 0, 0.8) !default;
$topbar-search-dropdown-border-size: em-calc(10px) !default;
// Determine which breakpoint triggers the display of the
// search field. Default of "medium" means on large screens
// the search field is displayed. Set this to "small" in
// order to also display the search field on tablets
// TODO - VFDP-18414 should move this to a config
// class in the HTML instead of SCSS so that brands can
// self-configure the switch
$topbar-search-field-breakpoint: medium !default;
$topbar-search-field-border: 1px solid $body-font-color !default;
$topbar-search-field-background: null !default;
$topbar-search-form-width: 178px !default;
$topbar-search-form-width-popup: 350px !default;
$topbar-search-container-icon-color: $body-font-color !default;
$topbar-search-input-background: null !default;
$topbar-search-top: auto !default;

$include-html-topbar-search-classes: $include-html-classes !default;

// Positions the search field, search panel
// and search icon across all breakpoints
@mixin topbar_search_position() {
	// Positions the group of search elements in the menu
	// On small screens, this appears after the main
	// nav as a popup, so no special positioning needed.
	@include grid-row();
	height: 0; // Hack for positioning purposes

	// On medium screens, show the search icon inline
	// with the main menu, but keep the popup under the
	// main nav, and right-taligned.
	@include breakpoint(medium) {
		// TODO - This is a total hack. We should not be
		// using the form element for positioning.
		//
		// height/width are 0 intentionally since this only
		// handles positioning and nothing else visual
		.menu-content {
			height: 0;
			position: absolute;
			right: $column-gutter / 2; // Align to page width
			top: $topbar-search-tablet-top-position;
			width: 0;
		}
	}

	// Make room for the full search box displayed
	// displayed inline with the main menu on medium
	// breakpoints when enabled, and always on large
	@include breakpoint($topbar-search-field-breakpoint) {
		.menu-content {
			height: auto;
			width: $topbar-search-form-width;
		}

		// TODO - Another total hack. This wrapper box is used
		// for the visual input field styling on large screens.
		// On medium and small screens it's not visible only
		// because the child contents are positioned absolute
		// making this box collapse.
		.search-container {
			@include grid-row();
			@include clearfix();
			background: $topbar-search-input-background;
			border: $topbar-search-field-border; // wrap border around both input and button
		}
	}

	@include breakpoint(large) {
		.menu-content {
			top: $topbar-search-top;
		}
	}
}

// The search field is displayed 2 ways,
// with a popup panel, or else always
// visible in the top navigation
@mixin topbar_search_field() {
	border: 0;
	box-shadow: none;
	// Minimum font size for mobile must be 16px
	// to avoid page zoom when interacting
	font-size: em-calc(16px);
	height: $topbar-lower-search-box-height;
	margin-bottom: 0;
	outline: none;
	padding: $topbar-lower-search-box-padding;
	width: 100%; // Control the width using the container

	// Show the search icon as a field on medium
	// breakpoints when enabled, and always on large
	@include breakpoint($topbar-search-field-breakpoint) {
		background: $topbar-search-field-background;
		font-size: convert-to-em($topbar-lower-search-box-font-size);
	}
}

// Controls the width and position of the search field
@mixin topbar_search_field_wrapper() {

	// On small screens search field is always in a popup panel
	// with a button adjacent
	@include grid-column(7, 0);

	// Medium screens also use a popup panel, though
	// the button can be a little smaller
	@include breakpoint(medium) {
		@include grid-column(8, 0);
	}

	// Display inline for tablet and desktop when
	// enabling the field view on medium
	// breakpoints when enabled, and always on large
	@include breakpoint($topbar-search-field-breakpoint) {
		float: right;
		margin-right: $topbar-lower-search-box-height;
		// don't let the width overflow the search icon
		width: $topbar-search-form-width - $topbar-lower-search-box-height;
	}
}

// The popup search panel layout
@mixin topbar_search_panel() {
	@include clearfix;
	border: $topbar-search-dropdown-border-size solid $topbar-search-dropdown-background;
	position: absolute;
	top: $mobile-topbar-height;
	width: 100%;
	z-index: 202;

	@include breakpoint(medium) {
		right: 0;
		top: $topbar-lower-height - 9; // TODO - get rid of magic numbers
		width: $topbar-search-form-width-popup;
	}

	// When not a popup panel, the box is fully visible
	// and shouldn't have the dropdown background
	@include breakpoint($topbar-search-field-breakpoint) {
		border: 0;
		// Necessary to override Foundation 6 .hide
		// TODO: Remove this with VFDP-18414
		display: block !important;
		position: static;
		width: auto;
	}
}

// ***** THERE ARE 2 DIFFERENT "BUTTONS" TO STYLE ****** //

// This controls the width and presence of the
// button in the popup search panel
@mixin topbar_search_button_wrapper() {
	// On small screens we show a wider button
	// so that longer translations will fit
	@include grid-column(5, 0);

	// On medium screens we show the button 25% width
	@include breakpoint(medium) {
		@include grid-column(4, 0);
	}

	// When not a popup panel, we show the icon instead
	// of a button using a different element
	@include breakpoint($topbar-search-field-breakpoint) {
		display: none;
	}
}

// The button shown in the panel should be styled
// to match the brand's primary button style
@mixin topbar_search_button() {
	background-color: $button-primary;
	border: 1px solid $input-background;
	border-radius: 0; // TODO - Mobile devices are adding rounded corners because this doesn't use our global button class
	color: $button-color;
	font-family: $button-font-family;
	font-size: 16px; // Prevent device zooming when tapped
	height: $topbar-lower-search-box-height;
	width: 100%;
}

// The icon link button is shown in the menu (instead
// of the drop panel) and is also used to put an icon
// within the search box
@mixin topbar_search_icon() {
	// Icon is not shown on small screens
	// so styles here effectively start
	// for medium screens
	display: none;

	@include breakpoint(medium) {
		border: 0;
		color: $topbar-search-container-icon-color;
		display: block;
		font-size: $topbar-search-container-icon-font-size;
		height: $topbar-lower-search-box-height;
		line-height: $topbar-lower-search-box-height;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		text-align: center;
		text-decoration: none;
		top: 0;
		width: $topbar-lower-search-box-height;
	}

	// Show the search icon in the field on medium
	// breakpoints when enabled, and always on large
	@include breakpoint($topbar-search-field-breakpoint) {
		background: $topbar-search-field-background;
		color: $topbar-search-container-icon-color;
	}
}

// The dropdown arrow gives a small visual
// indicator to the user that the popup panel
// is related to the search icon they clicked.
@mixin topbar_search_dropdown_arrow() {
	display: none;

	// Only displayed on medium breakpoint
	@include breakpoint(medium) {
		border: inset $topbar-search-dropdown-arrow-size / 2;
		border-color: transparent transparent $topbar-search-dropdown-background;
		border-top-style: solid;
		display: block;
		height: $topbar-search-dropdown-arrow-size;
		position: absolute;
		right: 0;
		top: -1 * ($topbar-search-dropdown-arrow-size + $topbar-search-dropdown-border-size);
		width: $topbar-search-dropdown-arrow-size;
	}

	// Never displayed on desktop or on medium
	// breakpoints when field display is enabled
	@include breakpoint($topbar-search-field-breakpoint) {
		display: none;
	}
}

// Main mixin for consolidating all the topbar search stuff
@mixin topbar_search() {

	@include topbar_search_position();

	// Layout the popup search panel
	.topbar-search-form {
		@include topbar_search_panel();
	}

	// Search field wrapper
	.search-input-container {
		@include topbar_search_field_wrapper();
	}

	// Search field
	input {
		&.search-input-js {
			@include topbar_search_field();
		}
	}

	// Search button wrapper
	.search-button-container {
		@include topbar_search_button_wrapper();
	}

	// Search button displayed in the dropdown panel
	input {
		&.search-button {
			@include topbar_search_button();
		}
	}

	// Search icon displayed at medium and large breakpoints
	a {
		&.search-button {
			@include topbar_search_icon();
		}
	}

	// Popup search panel


	// Arrow indicator used as garnish on the dropdown panel
	span {
		&.dropdown-arrow {
			@include topbar_search_dropdown_arrow();
		}
	}

	.focus {
		outline: $defaultFocusColor auto 1px;
	}
}

// This mixin is a handy way to get around
// SCSSLint selector depth warnings. Plus it makes
// things a big more reusable and provides a nice
// way to enable/disable output of this entire module
@if $include-html-topbar-search-classes != false {
    // Styles applied to all .master-header .search-form-container elements
    .master-header {
    	&.active {
    		.overlay & {
				// used to ensure correct z-index positioning above background overlay
				z-index: z-index(reveal) + 1;
				position: relative;
			}
		}

    	.search-form-container {
        	@include topbar_search;
    	}
    }
}
