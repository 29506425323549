$topbar-utility-cart-icon: $icon-shopcart !default;
$topbar-utility-cart-count-background: get-color(primary) !default;
$topbar-utility-cart-count-color: $button-color !default;
$topbar-utility-chat-icon: $icon-chat !default;
$include-html-topbar-utility-classes: $include-html-classes !default;


// The Live Chat is a special link that
// is dynamically enabled/disabled via
// GTM based on customer service hours
@mixin utility-live-chat-link() {
	// TODO - Move the icon to HTML like other links
	div {
		display: inline-block;

		&::before {
			@include icomoonFont($topbar-utility-chat-icon);
			display: inline-block;
		}
	}

	.available {
		display: inline-block;

		@include breakpoint(medium) {
			display: none;
		}

		@include breakpoint(large) {
			display: inherit;
		}
	}

	.unavailable {
		display: none;
	}
}

// The shopping cart is replaced on mobile
// screens with a different button
@mixin utility-shopping-cart() {
	// On small screens there's a different cart button
	// in the header, so we don't show in utility nav
	display: none;
	@include breakpoint(medium) {
		display: inline;
	}

	// Hide the word "Cart"
	.shoppingbag-label {
		font-size: 0;
	}

	// Display the count of items in the cart
	.nav-shoppingbag-qty-js {
		background: $topbar-utility-cart-count-background;
		border-radius: 50%;
		color: $topbar-utility-cart-count-color;
		display: inline-block; // Keeps element a circle
		font-size: 12px;
		height: em-calc(22, 12);
		line-height: em-calc(22, 12);
		min-width: em-calc(22, 12);
		padding: 0 0.5em;
		text-align: center;
		vertical-align: top;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

@mixin utility-shopping-cart-link() {

}

// The Account link is a specail link only displayed after
// Javascript does checks against cookies
@mixin utility-my-account-link() {
	// TODO, these should be initialy hidden using the HTML class .hide
	display: none;
}


// The Sign in/Sign Out link is a special link only displayed after
// Javascript does checks against cookies
@mixin utility-sign-in-link() {
	// TODO, these should be initialy hidden using the HTML class .hide
	display: none;
}

@mixin topbar-utility-nav-item() {
	border-bottom: $topbar-border-bottom;

	@include breakpoint(medium) {
		border-bottom: 0;
	}

	a {
		color: $topbar-link-color;
		display: inline-block;
		font-family: $secondaryFont;
		font-size: $topbar-link-font-size;
		padding: 0 0 0 $topbar-height / 3;

		@include breakpoint(medium) {
			color: $topbar-upper-font-color;
			font-size: $topbar-upper-font-size;
			font-weight: normal;
			line-height: $topbar-upper-height;
			padding: $topbar-upper-padding;

		}

		@include breakpoint(large) {
			padding: $topbar-upper-tablet-padding;
		}

		&:hover,
		&:focus {
			background: $topbar-background;
			color: $topbar-font-color-hover;

			:nth-last-child(1) {
				text-decoration: underline;
			}
		}
	}

	.icon {
		display: inline;
		position: relative;
		padding-right: 3px;
	}

	&:last-child a {
		padding-right: 0;
	}

	.country-logo {
		display: inline-block;
		vertical-align: middle;
	}

}

@mixin topbar_utility() {
	background: $topbar-upper-bg-color;

	// Normal utility nav links
	.topnav-util-item {
		@include topbar-utility-nav-item();

	}

	// *** Special Links ***
	// Shopping Cart
	.unav-shoppingbag {
		@include utility-shopping-cart();

		a {
			@include utility-shopping-cart-link();
		}
	}

	// live chat
	// TODO, change this to a class instead of ID
	#unav-livechat-js a {
		@include utility-live-chat-link();
	}

	// Sign-in and My Account link is initially hidden because the state
	// will change after the page loads and JS can check cookies
	.unav-signing {
		@include utility-sign-in-link();
	}

	.unav-myaccount {
		@include utility-my-account-link();
	}
}

// This mixin is a handy way to get around
// SCSSLint selector depth warnings. Plus it makes
// things a big more reusable and provides a nice
// way to enable/disable output of this entire module
@if $include-html-topbar-utility-classes != false {
    .master-header .topnav-util {
        @include topbar_utility();
    }
}
