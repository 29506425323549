$topbar-dropdown-parent-link-height: ($topbar-height / 2) !default;

.master-header {
	.top-bar {
		.top-bar-section {
			.lower {
				li:not(.page-util) {
					@include setFontStyle($pageUtilNavHeaderContent);
					border-bottom: $topbar-border-bottom;
					font-size: convert-to-em($topbar-upper-mobile-link-size);

					.shop-all li {
						border-bottom: 0;
					}

					a {
						position: relative;
					}

					&.title {
						font-size: convert-to-em($topbar-upper-mobile-title-size);
					}

					&.scaling-promo {
						display: none;
					}

					&.featured-items-list {
						border-bottom: 0;
					}
				}

				.dropdown {
					li {
						&.title h5 {
							margin-bottom: 0;

							a {
								background-color: $topbar-dropdown-title-bkd-color;
								letter-spacing: $topbar-dropdown-title-letter-spacing;
								line-height: $topbar-dropdown-title-height;
								text-align: $topbar-dropdown-title-text-alignment;
							}
						}

						a.parent-link {
							background-color: $topbar-dropdown-parent-link-bkd-color;
							height: $topbar-dropdown-parent-link-height;
							line-height: $topbar-dropdown-parent-link-line-height;
						}
					}
				}

				.product-carousel {
					display: none;
				}

				.featured-items-list {

					.topnav-main-item-featured-item-header {
						border-bottom: $topbar-border-bottom;
					}
				}

				.nav-product-carousel {
					border-bottom: 0;
				}
			} //lower
		} //top-bar-section
	} //mobile top-bar
} // end #master-header


@include breakpoint(medium) {
	.master-header {
		.top-bar {
			.top-bar-section {
				.lower {
					li:not(.page-util) {
						border-bottom: 0;
						font-size: $global-font-size;

						&.scaling-promo {
							display: inline-block;
						}
    				}

					.product-carousel {
						display: block;
					}

					.featured-items-list {

						.topnav-main-item-featured-item-header {
							border-bottom: 0;
						}
					}
				} //lower
			} //top-bar-section
		} //mobile top-bar
	}
}
