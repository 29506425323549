$fixed-top-bar-z-index: 201  !default;

.header-container {
  min-height: $mobile-topbar-height;
    width: 100%;

    @include breakpoint(medium) {
        min-height: $topbar-lower-height + $topbar-nav-extension-top;
    }
}

.fixed-small .master-header {
    height: $mobile-topbar-height;
    min-height: $mobile-topbar-height;
    z-index: inherit;

    @include breakpoint(medium) {
        background: inherit;
        height: inherit;
        min-height: inherit;
    }

    .top-bar,
    .top-bar-acord {
        background: $topbar-bg-color;
        position: fixed;
        width: 100%;
        z-index: $fixed-top-bar-z-index;
        @include breakpoint(medium) {
            position: inherit;
            width: inherit;
            z-index: inherit;
        }

        &.expanded {
            position: inherit;

            .overlay & {
                position: relative;
                z-index: z-index(reveal) + 1;

                @include breakpoint(medium) {
                    position: inherit;
                    z-index: inherit;
                }
            }
        }

        .lower,
        .upper {
            background: $topbar-bg-color;
        }
    }
}

.fixed-medium {
    @include breakpoint(medium) {
        left: 0;
        position: fixed;
        top: 0;
        z-index: 101;
    }

    .master-header .search-form-container .menu-content {
        z-index: 101;
    }
}
