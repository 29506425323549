// Navigation / Header : Override File Variables
$navLogoHeight: 95px;
$navLogoWidth: 131px;
$navLogoMinHeightMobile: 46px;
$navLogoMinWidthMobile: 77px;
$desktopSearchToggleFeature: false !default;
$desktopSearchToggleIconSize: null !default;

@import 'global/topbar/topbar-main';

.master-header {
	.top-bar {

		@include breakpoint(medium) {
			.lower .sub-category {
				@include grid-column(3);

				@include breakpoint(large) {
					@include grid-column(2);

					&,
					&:last-child:not(:first-child) {
						float: none;
					}
				}
			}

			.lower .sub-category-header {
				padding-bottom: 0;
				padding-left: 15px;
			}

			.lower > .has-dropdown > a::after,
			.lower .has-dropdown-custom > a::after {
				@include icomoonFont($icon-arrow-down-indicator-open);
				font-size: 7px;
				font-weight: bold;
				text-align: center;
			}

			.lower .featured-items-list {
				@include grid-column(3);
				margin-top: 8px;

				.sub-category-header {
					font-size: 1em;
					padding: 3px 0 16px 15px;
				}

				li {
					height: auto;
					margin-bottom: 5px;

					.button-link {
						background-color: transparent;
						color: get-color(sky-blue);
						height: auto;
						letter-spacing: 0;
						line-height: 32px;
						padding: 0 15px;

						.catLabel {
							font-size: em-calc(21);
							font-weight: bold;
							margin: 0;
							padding: 5px 0;
							white-space: normal;
						}

						&::after {
							display: none;
							height: auto;
						}

						&:hover {
							background-color: transparent;
						}
					}
				}

				.featured-items-list {
					.featured-items {
						margin-top: 0.65em;

						> ul > li {
							margin-bottom: 0;
						}
					}
				}
			}

			.lower .sub-category {

				@include grid-column(3);

				@include breakpoint(large) {
					@include grid-column(2);

					& {
						float: none;
					}
				}

				.sub-category-header {
					font-size: 1em;
				}

				 .content {
					> li {
						a {
							font-size: 1em;
							font-weight: normal;
							padding: 3px 0;
							text-transform: none;
						}

						//Vans Custom classes added by merchandisers - VFDP-11364
						&.style-1 {
							a {
								font-weight: bold;
								text-transform: uppercase;
							}
						}

						&.style-2 {
							a {
								text-transform: uppercase;
							}
						}

						&.style-3 {
							a {
								font-weight: bold;
								height: auto;
								text-transform: uppercase;
								white-space: normal;
							}
						}

						//End of Vans customs classes

						.all {
							font-weight: bold;
							height: auto;
							text-transform: uppercase;
							white-space: normal;
						}
					}
				}
			}

			.lower {
				.has-dropdown > .dropdown {
					background: $black;
					padding-top: 10px;
				}

				.mega {
					.shop-all-nav {
						a {
							background-color: $topbar-dropdown-bg-transparent;
							color: $topbar-dropdown-font-color;
							margin-left: 0;
							padding-bottom: 0.85em;
							padding-left: 0;
						}
					}

					a {
						float: left;
					}
				}
			}
		}

		@include breakpoint(large) {
			.lower {
				.featured-items-list {
					@include grid-column(2);

					&,
					&:last-child:not(:first-child) {
						float: none;
					}

					margin-top: 5px;

					.featured-items {
						margin-top: 0;

						> ul > li {
							margin-bottom: 5px;
						}
					}
				}
			}
		}

		// On newer HTML the absolute positioning is handled on .lower-container
		.lower-container {
			// tablet and up
			@include breakpoint(medium) {
				padding-left: $navLogoWidth;
				right: 36px;
				top: 31px;

				.lower {
					position: relative;
					top: auto;
				}
			}

			// desktop and up
			@include breakpoint(large) {
				right: 198px;
				top: 31px;

				.lower {
					position: absolute;
					right: 198px;
					top: auto;
				}
			}
		}

		// On older HTML there is no .lower-container wrapper
		.lower {
			// tablet and up
			@include breakpoint(medium) {
				clear: right;
				margin-top: 0;
				position: absolute;
				right: 14px;
				top: 31px;
			}

			// desktop and up
			@include breakpoint(large) {
				right: 188px;
			}

			.featured-items-list {

				.featured-items > ul > li {
					height: auto;

					a {
						.catLabel {
							font-size: 1.105em;
							font-weight: bold;
							margin: 0;
							padding: 5px 0;
							white-space: normal;
						}

						&.button-link {
							background-color: transparent;
							color: get-color(sky-blue);
							height: auto;

							&:hover {
								background-color: transparent;
							}

							&:after {
								display: none;
								height: auto;
							}
						}
					}
				}

				//needed to override vfrs styles that are only in commerce
			    ul li {
			      & > a {
			        display: block;
			        width: 100%;
			        color: $topbar-link-color;
			        padding-#{$global-left}: $topbar-height / 3;
			        background: $topbar-dropdown-bg;
			        letter-spacing: normal;

			        &::after {
			        	content: none;
			        }

			      }

			      // Apply the hover link color when it has that class
			      &:hover > a {
			        background: $topbar-link-bg-hover;
			        color: $topbar-link-color-hover;
			      }

			      // Apply the active link color when it has that class
			      &.active > a {
			        background: $topbar-link-bg-active;
			        color: $topbar-link-color-active;
			      }
			    }
			}

			.mega ul li .all {
				font-weight: bold;
				height: auto;
				white-space: normal;
			}
		}
	}
}

@if ($desktopSearchToggleFeature) {
	@include breakpoint(medium) {
		.master-header {
			.top-bar {
				.lower-container {
					padding-right: rem-calc(60);

					.topnav-main {
						margin: 0 rem-calc(-140) 0 0;
					}
				}
			}

			#topnav-search-container {
				.search-container {
					border: 0;
					height: $desktopSearchToggleIconSize;
					margin: rem-calc(-5) 0 0 auto;
					overflow: hidden;
					width: $desktopSearchToggleIconSize;

					&.active {
						overflow: visible;

						.search-button {
							color: get-color(nero);
							display: flex;
							font-size: rem-calc(24);
						}

						.topbar-search-form {
							display: flex !important;
							z-index: 1;
						}

						.topbar-search--cta-close {
							display: flex;
						}

						.topbar-search--cta-initial {
							display: none;
						}
					}
				}

				.menu-content {
					outline: none;
					z-index: $topbar-search-form-zindex-medium;
					width: $desktopSearchToggleIconSize;
				}

				.search-input-container {
					float: left;
					margin: 0;
					width: 80%;
				}

				.dropdown-arrow {
					border-color: transparent transparent $white;
					border-top-style: solid;
					display: block;
					height: 1em;
					position: absolute;
					right: rem-calc(10);
					top: rem-calc(-16);
					width: 0;
				}

				.search-button {
					align-items: center;
					border-left: 1px solid $light-gray;
					display: none;
					justify-content: center;
					padding: 0 0 0 rem-calc(15);
					right: rem-calc(15);
					top: rem-calc(65);
					z-index: 1;
				}

				.topbar-search-form {
					background: $white;
					border: rem-calc(1) solid $light-gray;
					box-shadow: 0 2px 5px 0 rgba($black, 0.5);
					display: none !important;
					overflow: visible;
					padding: rem-calc(10);
					position: absolute;
					right: 0;
					width: rem-calc(300);
					z-index: 0;

					input {
						border: 0;

						&:focus {
							outline: 1px $white;
						}
					}
				}

				.topbar-search--cta {
					&-icon {
						align-items: center;
						border-radius: 50%;
						color: $white;
						cursor: pointer;
						display: flex;
						height: $desktopSearchToggleIconSize;
						justify-content: center;
						width: $desktopSearchToggleIconSize;
					}

					&-close {
						background: get-color(primary);
						display: none;
					}

					&-initial {
						background: get-color(nero);
						font-size: rem-calc(21);
					}
				}
			}
		}
	}
}

@include breakpoint(medium) {
	.master-header {
		.top-bar {
			.lower-container {
				padding-right: $topbar-search-form-width;
			}
		}
	}
}
