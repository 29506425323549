@mixin no-column($clearpadding: true) {
	width: auto;
	float: none;

	@if ($clearpadding) {
		padding: 0;
	}
}

// =================
// nav.delimited
// -----------------
.nav-delimited {
	margin: 0;
	padding: 0;
	list-style: none;
	font-weight: 500;

	li {
		float: left;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.label {
		margin-right: 20px;
		padding: 0;
		color: $fontGrey;
		background-color: transparent;
	}

	.sep {
		margin-left: 15px;
		margin-right: 15px;
	}
}
