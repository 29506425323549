//
// Navigation / Header
//

@import 'global/z-indexes';

$topbar-nav-seperator: null !default;
$upperLinkLanguageBGColor: #000 !default;
$upperLinkLanguageColor: #fff !default;
$upperLinkLanguageDropdownWidth: 130px !default;
$upperLinkLanguageHoverColor: $brandColorPalette1 !default;
$upperLinksColor: transparent !default;
$upperLinkShopcartBGColor: none !default;
$upperLinkShopcartBGColorHover: none !default;
$upperLinkShopcartColor: none !default;
$upperLinkShopcartColorHover: none !default;

$topbar-menu-padding: 0px $topbar-dropdown-toggle-width 0px $topbar-lower-slash-width !default;
$topbar-menu-padding-small: 0px $topbar-dropdown-toggle-width 0px $topbar-lower-slash-width !default;

$topbar-L1-image-hover-filter: null !default;
$topbar-L1-image-height: 15px !default;
$topbar-L1-image-top: null !default;

// Disables Foundation TopBar's CSS hover
// for opening dropdowns. Otherwise we can't
// delay the mouseover via javascript
@mixin disable-topbar-css-hover() {
	// DO NOT ADD ANY STYLES IN THIS MIXIN
	.js {
		@include breakpoint(medium) {
			.top-bar-section {
				.has-dropdown {
			        &.not-click:hover {
			          & > .dropdown {
			            display: none;
			          }
			        }
				}
			}
		}
	}
}

@include disable-topbar-css-hover;

.nav-extension {
	background-color: $topbar-lower-bg-color;
	display: none;
	height: $topbar-nav-extension-height;
	position: absolute;
	top: $topbar-nav-extension-top;
	width: 100%;
}

.master-header {
	@include grid-row();
	position: relative;

	.top-bar,
	.top-bar-acord {
		.top-bar-section {
			display: none;

			ul.dropdown-custom-style {
				overflow: hidden;
				position: absolute;
			}

			// L1 image
			// hidden image/visible text only on small screens
			// visible image/hidden text on medium/large & hover
			// hidden image/visible text only in more menu
			.topnav-main-item {
				> a {
					.l1-item-category {
						display: block;
					}

					img {
						@include breakpoint(medium) {
							// need to set image display to inline-block over visibility class default display: inherit
							display: inline-block !important; // sass-lint:disable-line no-important
							filter: none;
							height: $topbar-L1-image-height;
							position: relative;
							top: $topbar-L1-image-top;
						}
					}
				}

				&.hover > a,
				> a:hover {
					img {
						@include breakpoint(medium) {
							filter: $topbar-L1-image-hover-filter;
						}
					}
				}

				&.mega {
					@include breakpoint(medium) {
						> a img  { // overwriting visibility class and hiding image in more menu
							display: none !important; // sass-lint:disable-line no-important
						}

						.l1-item-category  { // overwriting visibility class and showing text in more menu
							display: inline-block !important; // sass-lint:disable-line no-important
						}
					}
				}
			}

			.lower .has-dropdown .dropdown {
				overflow: visible!important; //VFDP-26400 added this hack meanwhile we found what is adding the overflow:hidden in the WCS pages
			}
		}

		.lower .has-dropdown .dropdown {
			overflow: visible!important; //VFDP-26400 added this hack meanwhile we found what is adding the overflow:hidden in the WCS pages
		}

		&.expanded {
			height: auto;

			.top-bar-section {
				display: block;
			}

			.page-util {
				display: none;
				@include breakpoint(medium) {
					display: inherit;
				}
			}

			.overlay & {
				// used to ensure correct z-index positioning above background overlay
				position: relative;
				z-index: z-index(reveal) + 1;
				@include breakpoint(medium) {
					position: inherit;
					z-index: inherit;
				}
			}

		}
	}

	.top-bar,
	.top-bar-acord {
		@include breakpoint(medium) {
			.top-bar-section {
				display: block;
			}

			.top-bar-js-breakpoint {
				width: map-get($breakpoints, medium) + 1 !important;
			}

			.lower-container {
				background: $topbar-dropdown-bg-transparent;
				clear: right;
				height: $topbar-lower-height !important;
				left: 0;
				margin-top: $topbar-lower-margin;
				overflow: hidden;
				position: absolute;
				top: $topbar-lower-top-position;
				width: 100%;
			}

			.lower {
				background: $topbar-lower-bg-color;
				height: $topbar-lower-height !important;

				> .expanded {
					> .dropdown,
					> .dropdown-list {
						display: block;
					}
				}

				.dropdown-list {
					@include grid-row();
					background: $topbar-dropdown-bg-transparent;
					background-image: $backgroundColorTransparency;
					display: none;
					padding: $topbar-dropdown-padding;
					padding-left: $vertSpaceSmall;
					padding-right: $vertSpaceSmall;
					position: absolute;
					right: 0;
					width: 330px;
					z-index: z-index(topnav) + 11;

					.sub-category {
						@include grid-column-f4(6, $collapse: false);

						a {
							background: $topbar-dropdown-bg-transparent !important;
							line-height: inherit;
						}
					}

					.content {
						background-color: transparent;

						li {
							float: none;
						}
					}

					&.mini-list {
						position: relative;
					}
				}

				> li {
					background: $topbar-background;

					a {
						@include setFontStyle($pageUtilNavHeaderContent);
					}

					@if $topbar-nav-seperator != null {
						&:before {
							color: $topbar-upper-font-color;
							content: #{$topbar-nav-seperator};
							display: block;
							float: left;
							height: $topbar-lower-height;
							line-height: $topbar-lower-height;
							margin-top: -$topbar-lower-margin;
							text-align: center;
							width: $topbar-lower-slash-width;
						}

						&:first-child:before {
							content: none;
						}
					}

					> a {
						background: $topbar-dropdown-bg-transparent;
						color: $topbar-font-color;
						font-size: $topbar-lower-menu-font-size;
						font-weight: $topbar-font-weight;
						height: $topbar-lower-height;
						line-height: $topbar-lower-height;
						margin-top: -$topbar-lower-margin;
						padding: 0 0 0 $topbar-lower-slash-width;
					}

					// Use hover class instead of psuedo state
					// to ensure we don't have menu items get
					// stuck in a hover state on touch devices
					&.hover,
					&:not(.hover):hover {
						> a {
							color: $topbar-font-color-hover;
						}
					}


					// can not get the width of hidden element in ie8 so using visibility.//
					&.visible-nav-item {
						position: relative;
					}
				}

				> .has-dropdown,
				.has-dropdown-custom {
					position: relative;

					> a {
						@include breakpoint(medium) {
							padding: $topbar-menu-padding-small !important;
						}

						&:after {
							border: 0;
							border-top-color: $topbar-font-color;
							content: "";
							display: block;
							height: 7px;
							margin-#{$global-right}: $topbar-height / 3;
							margin-top: -($topbar-dropdown-toggle-size / 2);
							position: absolute;
							right: -($topbar-dropdown-toggle-size * 5);
							top: $topbar-lower-height / 2;
							width: 10px;
						}
					}

					> .dropdown {
						background: $backgroundColorTransparency;
						padding: $topbar-dropdown-padding;
						text-align: center;
						z-index: z-index(reveal);

						.shop-all {
							@include grid-row();
							display: none;
							text-align: left;

							> ul {
								background: $topbar-dropdown-bg-transparent;
								margin-left: 26.3%;

								ul {
									background: $topbar-dropdown-bg-transparent;
								}
							}

							@include breakpoint(large) {
								display: none;
							}
						}
					} //dropdown

					> .dropdown-arrow {
						border-width: $topbar-dropdown-arrow-width / 2;
						// Default is a bottom arrow that points up
						// this can be overridden in the brand by setting
						// the color and style on the border-top instead of border-bottom
						border-color: transparent transparent $topbar-dropdown-arrow-bg transparent;
						border-style: inset inset solid inset;
						bottom: -1px;
						display: none;
						height: $topbar-dropdown-arrow-width;
						left: 50%;
						margin-left: -1 * ($topbar-dropdown-arrow-width/2);
						position: absolute;
						width: $topbar-dropdown-arrow-width;
					}
				}

				.mega {
					display: inline-block;
					float: none;
					padding: 0;
					position: relative;
					text-align: left;
					vertical-align: top;
					width: $topbar-dropdown-mega-column-width;

					a {
						background: $topbar-dropdown-bg-transparent;
						font-weight: $topbar-dropdown-sub-header-font-weight;
						padding: 10px 0;

						&:after {
							content: " " !important;
						}
					}

					.shop-all-nav {
						display: block;

						a {
							border-bottom: thin solid #d3d3d3;
							font-size: 1.1em;
							font-weight: bold;
							margin-left: 1em;
							padding-bottom: 2em;
							text-transform: uppercase;
							width: 8em;
						}
					}

					@include breakpoint(large) {
						.shop-all-nav {
							display: none;
						}
					}

					li {
						a {
							@include setFontStyle($pageUtilNavContent);
							color: $topbar-dropdown-font-color;
							height: auto;
							line-height: $topbar-dropdown-link-height;
							padding: 0;
							white-space: normal;

							&:hover {
								color: $topbar-font-color-hover !important;
							}
						}
					}
				} //.mega

				.sub-category {
					padding: 0 0.625em;
					width: 17.9%;

					@include breakpoint(large) {
						width: $topbar-dropdown-mega-column-width;
					}

					.content {
						background-color: $topbar-dropdown-bg-transparent;
						display: inline-block;
						float: none;
						padding: 0;
						position: relative;
						text-align: left;
						vertical-align: top;
						width: 100%;

						&.dropdown {
							right: 0%;
						}

						> li a {
							font-size: em-calc($topbar-dropdown-font-size);
							letter-spacing: $topbar-dropdown-font-letter-spacing;
							text-transform: capitalize;
						}
					}
				}

				.sub-category-header {
					@include setFontStyle($pageUtilNavHeaderContent);
					color: $topbar-dropdown-button-link-color;
					padding-bottom: 10px;
				}

				.featured-items-list {
					width: 25%;
					padding-left: $column-gutter / 2;

					@include breakpoint(large) {
						width: 252px;
						padding-left: 0;
					}

					&.hide-for-small-only { //This allows us to override the global behavior of this class
						display: none !important;

						@include breakpoint(medium) {
							display: inline-block !important;
						}
					}

					.tablet-alt-display {
						.tablet-alt-header {
							padding-bottom: 0px;
							margin-bottom: 0px;
						}

						.sub-category-header {
							color: $topbar-dropdown-button-link-color !important;
							background: $topbar-dropdown-bg-transparent;
							font-weight: bold;
							line-height: 1;
						}
					}

					.featured-items {
						@include grid-column(12);
						margin-top: 5px;
						padding-left: 0;
						padding-right: $column-gutter / 2;

						@include breakpoint(large) {
							padding-right: $column-gutter * 2;
						}

						.sub-category-header a {
							font-size: inherit;
							padding: 0;

							&:hover {
								color: $topbar-font-color-hover;
							}
						}

						> ul {
							background-color: $topbar-dropdown-bg-transparent;
							float: left;
							width: 100%;

							> li {
								height: 32px;
								margin-bottom: 5px;

								a {
									display: block;
									line-height: 32px;
									vertical-align: middle;
									width: 100%;

									&.button-link {
										background-color: $header-color;
										color: $topbar-dropdown-button-link-color;
										display: block;
										height: 32px;
										line-height: 32px;
										padding: 0 15px;
										position: relative;
										text-transform: uppercase;

										&:after {
											border: solid transparent;
											border-left-color: $hyperlinkText;
											border-width: 3px;
											content: " ";
											height: 0;
											margin-top: -3px;
											pointer-events: none;
											position: absolute;
											right: 10px;
											top: 50%;
											width: 0;
										}

										&:hover {
											background-color: $white;
											color: $header-color;

											&:after {
												border-left-color: $brandColorPalette1;
											}
										}
									} //button-link
								}
							}
						}

						.button-link {
							font-size: convert-to-em($topbar-dropdown-font-size);
						} //.button-link
					} //.featured-items
				} //.featured-items list

				.thumbnails {
					.sub-categories {
						@include grid-row();

						.item {
							display: inline-block !important;
							float: none !important;
							margin: 0 $topbar-dropdown-padding-top / 4;
							vertical-align: top !important;
							width: $topbar-kids-item-width;

							.img,
							.desc {
								background: $topbar-dropdown-bg-transparent !important;
								padding: 0 !important;
								text-align: center !important;
								white-space: normal !important;

								img {
									height: 120px;
									margin-bottom: 10px;
									width: 120px;
								}

								span {
									@include setFontStyle($pageUtilNavContent);
								}
							}

							.desc {
								@include setFontStyle($pageUtilNavContent);
								color: $topbar-dropdown-font-color !important;

								&:hover {
									color: $topbar-font-color-hover !important;
								}
							}

							&:hover {
								span {
									color: $topbar-dropdown-link-color !important;
								}
							}
						} // .item
					}
				} // thumbnails

				.nav-product-carousel {
					width: 277px;
					display: none;

					@include breakpoint(large) {
						display: inline-block;
					}

					.product-carousel {
						margin-top: 5px;
						text-align: center;

						.header-nav-carousel {
							background: transparent;
							display: block;
							height: 230px !important;
						}

						.slide-img {
							width: 100%;
						}

						.name {
							font-size: em-calc(14px);
							height: auto;
							margin: 4px 0 0;

							a {
								color: $negativeLightText;

								span {
									font-family: $secondaryFont;
									font-weight: normal !important;
									text-transform: none;
								}
							}
						}

						.current-price {
							color: $priceFontColor;
							font-size: em-calc(14px);
							font-weight: normal;
						}
					} // product-carousel
				} // nav-product-carousel

				.dropdown.stacked { // styling for stacked list template
					.featured-items-list {
						max-width: none;
						min-width: 0;
						width: 22%;

						.featured-items a.button-link {
							display: table;
							height: 100%;
							line-height: normal;
							padding: 8px 15px;
							white-space: normal;
						}

						@include breakpoint(large) {
							max-width: 198px;
							min-width: 175px;
							width: 13%;
						}
					}

					.sub-section {
						display: inline-block;
						float: none;
						max-width: initial;
						min-width: initial;
						width: 70%;

						> li {
							display: inline-block;
							width: inherit;
						}

						.stacked {
							display: none;
						}

						a,
						li {
							height: auto;
							overflow: hidden;
							padding-right: 0 !important; // override platform important declarative
							white-space: normal;
							word-wrap: break-word;
						}

						@include breakpoint(large) {
							max-width: 700px;
							min-width: 571px;
							width: 60%;
						}
					}

					.sub-category {
						display: inline-block;
						max-width: initial;
						min-width: initial;
						width: 32.5%;

						@include breakpoint(large) {
							max-width: 230px;
							min-width: 181px;
							width: 32.8%;
						}
					}

					.nav-product-carousel {
						min-width: 180px;
						width: 180px;
					}
				}// .dropdown.stacked

				.dropdown.mini-list { // styling for mini-list template
					left: auto !important; // override js
					margin-right: 0;

					.sub-category {
						border-left: 0;
						display: inline-block;
						max-width: 230px;
						min-width: 181px;
						padding: 0 10px;
						text-align: left;

						a {
							background-color: $topbar-dropdown-bg;
							font-size: 13px;
							height: auto;
							overflow: hidden;
							padding: 10px;
							white-space: normal;
							word-wrap: break-word;
						}

						.sub-category-header {
							font-size: 17px;
							padding: 10px 0;
							text-align: left;

							&:after {
								float: right;
							}
						}

						.content a {
							margin-bottom: 5px;
						}
					}

					.mini-list-image,
					.mini-list-action,
					.page-util {
						display: none;
					}

					.next-arrow {
						margin-left: 20px;
					}
				} // .dropdown.mini-list
			} //lower
		}
	} //.top-bar
}
