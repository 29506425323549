@import 'global/topbar/topbar-title';

$small-header-nav-title-area-display: block !default;
$small-header-nav-toggle-topbar-order: 4 !default;
$small-header-nav-cart-topbar-order: 3 !default;
$small-header-nav-locator-topbar-order: 1 !default;
$small-header-nav-search-topbar-order: 2 !default;
$master-header-header-logo-width: $navLogoMinWidthMobile !default;
$master-header-header-logo-width-medium: $navLogoWidth !default;
$master-header-header-logo-height-medium: $navLogoHeight !default;
$master-header-header-logo-margin-left: 10px !default;
$master-header-header-logo-margin-top: ($mobile-topbar-height - 46px) / 2 !default;

.master-header {
	.header-logo {
		background: $store-logo 0 center no-repeat;
		background-size: 100%;
		height: $navLogoMinHeightMobile;
		margin-left: $master-header-header-logo-margin-left;
		margin-top: $master-header-header-logo-margin-top;
		position: static;
		width: $master-header-header-logo-width;

		@include breakpoint(medium) {
			height: $master-header-header-logo-height-medium;
			margin-left: $column-gutter / 2;
			margin-top: 0;
			width: $master-header-header-logo-width-medium;
		}
	}

	.top-bar .title-area {
		//.menu-topbar is only visible on small screens
		.menu-topbar {
			.search-icon,
			.cart-icon,
			.menu-icon,
			.locator-icon {
				color: $mobile-topbar-button-color;
				display: block;
				font-size: 20px;
				line-height: $mobile-topbar-height;
				position: static;
				width: $mobile-topbar-button-width;

				&:hover {
					color: $mobile-topbar-button-hover-color;
				}
			}

			.cart-icon {
				color: get-color(primary);
			}

			.cart-items-count {
				font-size: 14px;
				font-style: normal;
				font-weight: bold;
			}
		}
	}

	.small-header-nav {
		.title-area {
			display: $small-header-nav-title-area-display;
		}

		.toggle-topbar {
			order: $small-header-nav-toggle-topbar-order;
		}

		.cart-topbar {
			order: $small-header-nav-cart-topbar-order;
		}

		.locator-topbar {
			order: $small-header-nav-locator-topbar-order;
		}

		.search-topbar {
			order: $small-header-nav-search-topbar-order;
		}
	}
}
