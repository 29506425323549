// This file is currently only used by Vans
// TODO: DELETE this file when Vans switches to the CMS topnav

//
// Top Bar Variables
//

// Height and margin
$topbar-height: 45px !default;
$topbar-margin-bottom: 0 !default;

// Control Input height for top bar
$topbar-input-height: 2.45em !default;

// Controlling the styles for the title in the top bar
$topbar-title-weight: bold !default;
$topbar-title-font-size: em-calc(17) !default;

// Style the top bar dropdown elements
$topbar-dropdown-bg: #222 !default;
$topbar-dropdown-link-color: #fff !default;
$topbar-dropdown-link-bg: lighten($topbar-background, 5%) !default;
$topbar-dropdown-toggle-size: 5px !default;
$topbar-dropdown-toggle-color: #fff !default;
$topbar-dropdown-toggle-alpha: 0.5 !default;

// Set the link colors and styles for top-level nav
$topbar-link-color: #fff !default;
$topbar-link-color-hover: #fff !default;
$topbar-link-color-active: #fff !default;
$topbar-link-weight: bold !default;
$topbar-link-font-size: em-calc(13) !default;
$topbar-link-hover-lightness: -30% !default; // Darken by 30%
$topbar-link-bg-hover: adjust-color($topbar-dropdown-bg, $lightness: $topbar-link-hover-lightness) !default;
$topbar-link-bg-active: darken($topbar-background, 3%) !default;


$topbar-dropdown-label-color: #555 !default;
$topbar-dropdown-label-text-transform: uppercase !default;
$topbar-dropdown-label-font-weight: bold !default;
$topbar-dropdown-label-font-size: em-calc(10) !default;
$topbar-dropdown-label-bg: lighten($topbar-background, 5%) !default;

// Top menu icon styles
$topbar-menu-link-transform: uppercase !default;
$topbar-menu-link-font-size: em-calc(13) !default;
$topbar-menu-link-weight: bold !default;
$topbar-menu-link-color: #fff !default;
$topbar-menu-icon-color: #fff !default;
$topbar-menu-link-color-toggled: #888 !default;
$topbar-menu-icon-color-toggled: #888 !default;

// Transitions and breakpoint styles
$topbar-transition-speed: 300ms !default;
// Using em-calc for the below breakpoint causes issues with top bar
$topbar-breakpoint: 940px !default; // Change to 9999px for always mobile layout

// Divider Styles
$topbar-divider-border-bottom: solid 1px lighten($topbar-background, 10%) !default;
$topbar-divider-border-top: solid 1px darken($topbar-background, 10%) !default;

// Sticky Class
$topbar-sticky-class: '.sticky' !default;
$topbar-arrows: true !default; //Set false to remove the triangle icon from the menu item

$contain-to-grid-height: null !default;
$contain-to-grid-box-shadow: null !default;


@mixin top-bar-f4 {
  // Wrapped around .top-bar to contain to grid width
  .contain-to-grid {
    background: $topbar-background;
    box-shadow: $contain-to-grid-box-shadow;
    height: $contain-to-grid-height;
    width: 100%;

    .top-bar {
      margin-bottom: $topbar-margin-bottom;
    }
  }

  // Wrapped around .top-bar to make it stick to the top
  .fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    #{$global-left}: 0;

    &.expanded:not(.top-bar) {
      height: auto;
      max-height: 100%;
      overflow-y: auto;
      width: 100%;

      .title-area {
        position: fixed;
        width: 100%;
        z-index: 99;
      }

       // Ensure you can scroll the menu on small screens
       .top-bar-section {
        margin-top: $topbar-height;
        z-index: 98;
      }
    }
  }

  .top-bar {
    background: $topbar-background;
    height: $topbar-height;
    line-height: $topbar-height;
    margin-bottom: $topbar-margin-bottom;
    overflow: hidden;
    position: relative;

    // Topbar Global list Styles
    ul {
      list-style: none;
      margin-bottom: 0;
    }

    .row {
      max-width: none;
    }

    form,
    input {
      margin-bottom: 0;
    }

    input {
      height: $topbar-input-height;
    }

    .button {
      margin-bottom: 0;
      padding-bottom: 0.5em;
      padding-top: 0.5em;
    }

    // Title Area
    .title-area {
      margin: 0;
      position: relative;
    }

    .name {
      font-size: $global-font-size;
      height: $topbar-height;
      margin: 0;

      h1 {
        font-size: $topbar-title-font-size;
        line-height: $topbar-height;
        margin: 0;

        a {
          color: $topbar-link-color;
          display: block;
          font-weight: $topbar-title-weight;
          padding: 0 $topbar-height / 3;
          width: 50%;
        }
      }
    }

    // Menu toggle button on small devices
    .toggle-topbar {
      position: absolute;
      #{$global-right}: 0;
      top: 0;

      a {
        color: $topbar-link-color;
        display: block;
        font-size: $topbar-menu-link-font-size;
        font-weight: $topbar-menu-link-weight;
        height: $topbar-height;
        line-height: $topbar-height;
        padding: 0 $topbar-height / 3;
        position: relative;
        text-transform: $topbar-menu-link-transform;
      }

      // Adding the class "menu-icon" will add the 3-line icon people love and adore.
      &.menu-icon {
        margin-top: -16px;
        padding-#{$global-left}: 40px;
        top: 50%;
        #{$global-right}: $topbar-height / 3;

        a {
          color: $topbar-menu-link-color;
          height: 34px;
          line-height: 33px;
          padding: 0;
          text-indent: -48px;
          width: 34px;

          span {
            box-shadow: 0 10px 0 1px $topbar-menu-icon-color,
                        0 16px 0 1px $topbar-menu-icon-color,
                        0 22px 0 1px $topbar-menu-icon-color;
            display: block;
            height: 0;
            position: absolute;
            width: 16px;
            #{$global-right}: 0;
          }
        }
      }
    }

    // Change things up when the top-bar is expanded
    &.expanded {
      height: auto;
      background: transparent;

      .title-area {
        background: $topbar-background;
      }

      .toggle-topbar {
        a {
          color: $topbar-menu-link-color-toggled;

          span {
            box-shadow: 0 10px 0 1px $topbar-menu-icon-color-toggled,
                        0 16px 0 1px $topbar-menu-icon-color-toggled,
                        0 22px 0 1px $topbar-menu-icon-color-toggled;
          }
        }
      }
    }

  }

  // Right and Left Navigation that stacked by default
  .top-bar-section {
    #{$global-left}: 0;
    position: relative;
    width: auto;
    transition: $global-left $topbar-transition-speed ease-out;

    ul {
      background: $topbar-dropdown-bg;
      display: block;
      font-size: $global-font-size;
      height: auto;
      margin: 0;
      width: 100%;
    }

    .divider,
    [role='separator'] {
      border-bottom: $topbar-divider-border-bottom;
      border-top: $topbar-divider-border-top;
      clear: both;
      height: 1px;
      width: 100%;
    }

    ul li {
      & > a {
        display: block;
        width: 100%;
        color: $topbar-link-color;
        padding: 12px 0 12px 0;
        padding-#{$global-left}: $topbar-height / 3;
        font-size: $topbar-link-font-size;
        font-weight: $topbar-link-weight;
        background: $topbar-dropdown-bg;

        &.button {
          background: get-color(primary);
          font-size: $topbar-link-font-size;
          padding-right: $topbar-height / 3;
          padding-left: $topbar-height / 3;

          &:hover {
            background: darken(get-color(primary), 10%);
          }
        }

        &.button.secondary {
          background: get-color(secondary);

          &:hover {
            background: darken(get-color(secondary), 10%);
          }
        }

        &.button.success {
          background: get-color(success);

          &:hover {
            background: darken(get-color(success), 10%);
          }
        }

        &.button.alert {
          background: $alert-color;

          &:hover {
            background: darken($alert-color, 10%);
          }
        }

      }

      // Apply the hover link color when it has that class
      &:hover > a {
        color: $topbar-link-color-hover;
      }

      // Apply the active link color when it has that class
      &.active > a {
        background: $topbar-link-bg-active;
        color: $topbar-link-color-active;
      }
    }

    // Add some extra padding for list items contains buttons
    .has-form {
      padding: $topbar-height / 3;
    }

    // Styling for list items that have a dropdown within them.
    .has-dropdown {
      position: relative;

      & > a {
        &:after {
          @if ($topbar-arrows) {
            @include css-triangle($topbar-dropdown-toggle-size, rgba($topbar-dropdown-toggle-color, $topbar-dropdown-toggle-alpha), $global-right);
          }
          margin-#{$global-right}: $topbar-height / 3;
          margin-top: -($topbar-dropdown-toggle-size / 2) - 2;
          position: absolute;
          top: 50%;
          #{$global-right}: 0;
        }
      }

      &.moved {
        position: static;

        & > .dropdown {
          display: block;
        }
      }
    }

    // Styling elements inside of dropdowns
    .dropdown {
      display: none;
      position: absolute;
      #{$global-left}: 100%;
      top: 0;
      z-index: 99;

      li {
        width: 100%;
        height: auto;

        a {
          font-weight: normal;
          padding: 8px $topbar-height / 3;

          &.parent-link {
            font-weight: $topbar-link-weight;
          }
        }

        &.title h5 {
          margin-bottom: 0;

          a {
            color: $topbar-link-color;
            display: block;
            line-height: $topbar-height / 2;
          }
        }
      }

      label {
        padding: 8px $topbar-height / 3 2px;
        margin-bottom: 0;
        text-transform: $topbar-dropdown-label-text-transform;
        color: $topbar-dropdown-label-color;
        font-weight: $topbar-dropdown-label-font-weight;
        font-size: $topbar-dropdown-label-font-size;
      }
    }
  }

  // Element that controls breakpoint, no need to change this ever
  .top-bar-js-breakpoint {
    visibility: hidden;
    width: $topbar-breakpoint !important;
  }

  .js-generated {
    display: block;
  }


  // Top Bar styles intended for screen sizes above the breakpoint.
  @media only screen and (min-width: #{$topbar-breakpoint}) {
    .top-bar {
      @include clearfix;
      background: $topbar-background;
      overflow: visible;

      .toggle-topbar {
        display: none;
      }

      .title-area {
        float: $global-left;
      }

      .name h1 a {
        width: auto;
      }

      input,
      .button {
        line-height: 2em;
        font-size: em-calc(14);
        height: 2em;
        padding: 0 10px;
        position: relative;
        top: 8px;
      }

      &.expanded {
        background: $topbar-background;
      }
    }

    .contain-to-grid .top-bar {
      max-width: $global-width;
      margin: 0 auto;
      margin-bottom: $topbar-margin-bottom;
    }

    .top-bar-section {
      transition: none 0 0;
      #{$global-left}: 0 !important;

      ul {
        width: auto;
        height: auto !important;
        display: inline;

        li {
          float: $global-left;

          .js-generated {
            display: none;
          }
        }
      }

      li {
        &.hover {
          > a:not(.button) {
            color: $topbar-link-color-hover;
          }
        }

        a:not(.button) {
          background: $topbar-background;
        }
      }

      .has-dropdown {

        @if($topbar-arrows) {

          & > a {
            padding-#{$global-right}: $topbar-height / 3 + 20 !important;

            &:after {
              @include css-triangle($topbar-dropdown-toggle-size, rgba($topbar-dropdown-toggle-color, $topbar-dropdown-toggle-alpha), down);
              margin-top: -($topbar-dropdown-toggle-size / 2);
              top: $topbar-height / 2;
            }
          }

        }

        &.moved {
          position: relative;

          & > .dropdown {
            display: none;
          }
        }

        &.hover,
        &.not-click:hover {
          & > .dropdown {
            display: block;
          }
        }

        .dropdown li.has-dropdown {
          & > a {
            &:after {
              border: 0;
              content: '\00bb';
              top: 1em;
              margin-top: -7px;
              #{$global-right}: 5px;
            }
          }
        }

      }

      .dropdown {
        #{$global-left}: 0;
        top: auto;
        background: transparent;
        min-width: 100%;

        li {
          a {
            color: $topbar-dropdown-link-color;
            line-height: 1;
            white-space: nowrap;
            padding: 7px $topbar-height / 3;
            background: $topbar-dropdown-link-bg;
          }

          label {
            white-space: nowrap;
            background: $topbar-dropdown-label-bg;
          }

          // Second Level Dropdowns
          .dropdown {
            #{$global-left}: 100%;
            top: 0;
          }
        }
      }

      & > ul > .divider,
      & > ul > [role='separator'] {
        border-bottom: 0;
        border-top: 0;
        border-#{$global-right}: $topbar-divider-border-bottom;
        border-#{$global-left}: $topbar-divider-border-top;
        clear: none;
        height: $topbar-height;
        width: 0;
      }

      .has-form {
        background: $topbar-background;
        padding: 0 $topbar-height / 3;
        height: $topbar-height;
      }

      // Position overrides for ul.right
      ul.right {
        li .dropdown {
          left: auto;
          right: 0;

          li .dropdown {
            right: 100%;
          }
        }
      }
    }

    // Degrade gracefully when Javascript is disabled. Displays dropdown and changes
    // background & text color on hover.
    .no-js .top-bar-section {
      ul li {
          // Apply the hover link color when it has that class
          &:hover > a {
            background: $topbar-link-bg-hover;
            color: $topbar-link-color-hover;
          }

          // Apply the active link color when it has that class
          &:active > a {
            background: $topbar-link-bg-active;
            color: $topbar-link-color-active;
          }
        }

      .has-dropdown {
          &:hover {
            & > .dropdown {
              display: block;
            }
          }
      }
	  }
  }
}
