$include-html-topbar-utility-classes: false;
@import 'global/topbar/topbar-utility';
$include-html-topbar-utility-classes: true;

@mixin topbar_utility_vans() {

	background: $topbar-dropdown-bg;

	@include breakpoint(medium) {
		background: none;
		margin-right: $column-gutter / 2;
	}

	// Normal utility nav links
	li { //used here li tag instead of .topnav-util-item so we can add the same styles to #unav-livechat-js
		@include topbar-utility-nav-item();

		a {
			font-family: $header-font-family;
			font-weight: normal;
			padding-bottom: 12px;
			padding-top: 12px;

			@include breakpoint(medium) {
				font-family: inherit;
				padding-bottom: 0;
				padding-top: 10px;
			}

			& > span:not(.icon) {
				margin-left: em-calc(12px);

				@include breakpoint(medium) {
					margin-left: 0;
				}
			}
		}

		.icon {
			float: left;
			font-size: em-calc(15px);
			margin-top: em-calc(2px, 15px);
			vertical-align: middle;
			width: em-calc(23px, 15px);

			@include breakpoint(medium) {
				float: none;
				margin-top: 0;
			}
		}

		//Country selector flag
		.country-logo {
			line-height: 22px;
			vertical-align: top;
			width: 18px;

			@include breakpoint(medium) {
				width: 25px;
	 		}
		}
	}

	#unav-livechat-js {
		@include utility-live-chat-link();

		a {
			display: flex;
			padding: 0;

			div {
				width: 100%;

				&::before {
					display: none;

					@include breakpoint(large) {
						display: none;
					}
				}
			}

			span {
				&::before {
					@include icomoonFont($topbar-utility-chat-icon);
					float: left;
					font-size: em-calc(15px);
					margin-right: em-calc(12px, 15px);
					margin-top: em-calc(2px, 15px);
					width: em-calc(23px, 15px);

					@include breakpoint(medium) {
						float: none;
						font-size: 12px;
						margin-right: em-calc(5px);
						margin-top: 0;
					}

					@include breakpoint(large) {
						font-size: em-calc(15px);
					}
				}

			}

			.available {
				display: inline-block;
				padding: 12px 0 12px $topbar-height / 3;
				width: 100%;

				@include breakpoint(medium) {
					font-size: 0;
					padding: 15px 10px 0px 10px;
				}

				@include breakpoint(large) {
					font-size: inherit;
					padding: 10px;
				}
			}
		}


	}

	.unav-wishlist {
		#nav-wishlist-qty {
			@include topbar-cart-wish-badge($topbar-wish-badge-size, $topbar-wish-badge-color, $topbar-wish-badge-text);

			&.has-products {
				@include topbar-cart-wish-visible();
			}
		}
	}


	// Shopping Cart
	.unav-shoppingbag {
		// On small screens there's a different cart button
		display: none;
		@include breakpoint(medium) {
			display: inline;
		}

		#nav-shoppingbag-qty {
			@include topbar-cart-wish-badge($topbar-cart-badge-size, $topbar-cart-badge-color, $topbar-cart-badge-text);
		}

		&.has-products #nav-shoppingbag-qty {
			@include topbar-cart-wish-visible();
		}
	}


	// Sign-in and My Account link is initially hidden because the state
	// will change after the page loads and JS can check cookies
	.unav-signing {
		@include utility-sign-in-link();
	}

	.unav-myaccount {
		@include utility-my-account-link();
	}

	.unav-greetname {
		span {
			margin-left: 0;
		}
	}
}

// This mixin is a handy way to get around
// SCSSLint selector depth warnings. Plus it makes
// things a big more reusable and provides a nice
// way to enable/disable output of this entire module
@if $include-html-topbar-utility-classes != false {
    .master-header .topnav-util {
        @include topbar_utility_vans();
    }
}
